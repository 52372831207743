export default (obj: any) => deleteUndefined(obj);

const deleteUndefined = (obj: any) => {
  for (const key in obj) {
    if (obj[key] === undefined || obj[key] === null) {
      delete obj[key];
    } else if (typeof obj[key] === 'object') {
      deleteUndefined(obj[key]);
    } else if (Array.isArray(obj[key])) {
      obj[key].forEach((item: any) => deleteUndefined(item));
    }
  }
  return obj;
};
